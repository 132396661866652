body {
    color: rgb(145, 145, 161);
}
.App {
    text-align: center;
}

.App-logo {
    width: 20px;
    margin: 0 5px 0 5px;
    pointer-events: stroke;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #088d5a;
}
.nav-link {
    color: #088d5a;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* .card{
  align-items: center;
  margin: 5px 0 5px 0;
} */
.nav-item a {
    text-decoration: none;
    float: left;
    padding: 0.3rem 0.5rem;
    color: #111111;
    width: 100%;
    text-align: left;
    font-size: 16px;
}
.nav-item a:hover {
    background-color: #d1e7dd;
}

.nav-item svg {
    margin-top: -4px;
    margin-right: 4px;
}
.nav-item svg {
    margin-top: -4px;
    margin-right: 4px;
}
header a svg {
    margin-top: 5px;
    float: left;
}
/* th,.nav-tabs .nav-link.active{
  color:green;
  font-weight: 500;
} */
.nav-link {
    color: rgb(17, 17, 17);
}

.btn-group svg {
    margin-right: 4px;
}
.spinner-grow,
.toast {
    position: absolute;
    z-index: 1056;
}
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* padding: 48px 0 0; */
    box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}
.table-responsive {
    display: inline-table;
}
#feedback {
    color: #ff0000;
    background-color: #fff;
    margin-top: 0px;
}
#actionbtn {
    text-decoration: none;
    margin-left: 10px;
    font-size: small;
    color: #585757;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
#actionbtns {
    text-decoration: none;
    color: #e60808;
    font-weight: bold;
    text-align: center;
}
#actionbtns:hover {
    text-decoration: none;
    color: #222423;
    font-weight: bold;
    text-align: center;
}
.btn-secondary {
    color: #090909;
    background-color: #d2cfcf;
}
#btn-secondary {
    color: #090909;
}

#btn-secondary:hover,
.btn-secondary:hover {
    color: #e60808;
}

.user {
    font-size: xx-small;
    margin-right: 10px;
    margin-top: 20px;
}
.h2 {
    color: green;
}
/* input[type='radio'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: red;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid red;
    border-radius: 50%;
    transform: translateY(-0.075em);
}
input[type='radio']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(rgb(17, 17, 17));
}

input[type='radio']:checked::before {
    transform: scale(1);
    border: 0.15em solid rgb(0, 20, 98);
}
input[type='radio']:focus {
    outline: max(2px, 0.15em) solid #000;
    outline-offset: max(2px, 0.15em);
} */
.user {
    color: #555555;
    font-weight: 500;
    text-transform: uppercase;
}

.imgart {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    margin-top: -17px;
    margin-left: -6px;
    position: absolute;
    z-index: 100;
}

.userimg {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    /* margin-top: -20px; */
    /* position: absolute; */
    /* z-index: -10; */
}
.imgartUpload {
    display: flex;
    position: relative;
    border-radius: 6px;
    width: 100px;
    height: 100px;
    background-color: rgba(234, 235, 241, 0.7);
}
.imgart-big {
    width: 100%;
    border-radius: 50px;
}

.modal {
    z-index: 1050;
}
.btn-group {
    margin-left: 5px;
}
.btn-group a {
    color: #fff;
    font-size: x-small;
    text-decoration: none;
    width: 30px;
    height: 10px;
}
#dropdown-basic {
    padding: 5px 7px;
}
#toast {
    position: fixed;
    right: 0;
}
.dark {
    color: #000;
}
div .hovers:hover {
    background-color: rgb(214, 248, 164);
}
.p {
    text-align: left;
    font-size: 1em;
}
.breadcrumb {
    margin: revert;
    font-size: small;
}
.breadcrumb a {
    text-decoration: none;
}

.rgt-cell {
    font-style: normal;
    font-stretch: normal;
    font-family: Poppins, sans-serif;
    line-height: 1.43;
    color: rgb(72, 75, 88);
    font-size: 13px;
    font-weight: 500;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
}

#lightbody {
    min-height: 400px;
    max-height: 600px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
#carrouDel {
    position: relative;
}
#carrouDel button {
    margin: 0;
    position: absolute;
    left: 50%;
}
.nav li.actives > a {
    color: #e60808;
}

.nav li.actives > a i {
    color: #e60808;
    font-weight: bold;
}
input[type='checkbox'] {
    width: 25px;
    height: 25px;
    border: 2px dotted #0043ff;
    margin: 0 3px 5px 0px;
}
input[type='checkbox']:checked {
    background-color: #0043ff;
}
#light,
#warning,
#success,
#successs,
#blue,
#orange {
    padding: 2px;
    border-radius: 4px;
    /* border: 1px solid #8d8c8c; */
    margin: auto;
    text-align: center;
    /* width: 60px; */
    color: rgb(71, 71, 71);
}
#warning {
    background-color: #e6d408;
    color: #181717;
}
#light {
    background-color: rgb(245, 242, 242);
}
#lights,
#lightr {
    /* width: 80%; */
    font-size: 18px;
    background-color: rgb(255, 255, 255);
}
#lights {
    color: #06c27a;
}
#lightr {
    background-color: #1f1f1f;
    color: #ffffff;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
}
#success {
    background-color: #e60808;
    color: #fff;
}
#successs {
    background-color: #00ffaa;
}
#blue {
    background-color: #00ccff;
}
#orange {
    background-color: #ffaa17;
}

.nav div {
    display: flex;
}
.nav div .content {
    margin: 0 10px 0 10px;
}
.centerCard {
    position: relative;
    left: 40%;
    top: 50%;
    margin: 100px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 1px solid rgb(192, 192, 192);
    margin-top: 150px;
}
/* .centerCard .card-body {
    margin-top: 30px;
} */
.list-group-item.active {
    background-color: rgb(192, 192, 192);
    border-color: rgb(192, 192, 192);
}
input[type='file'] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #918f8f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 0, 0);
}

.sidebar {
    background-color: #8d8c8c;
}
#tablecontentmin {
    height: 300px;
}
#tablecontent {
    overflow: auto;
    position: relative;
    flex: 1;
    margin: 0;
}
.content {
    overflow: hidden;
}

.box {
    width: auto;
    border: 1px solid #fff;
}
.box .steps {
    border-bottom: 1px solid #ddd;
}
.box .steps ul {
    overflow: hidden;
}
.box .steps ul li div {
    color: rgb(249, 249, 249);
    padding: 10px 0 15px 45px;
    position: relative;
    background: #e60808;
    width: 205px;
    /* height: 120px; */
}
.box .steps ul li div span {
    font-size: 13px;
    font-weight: bold;
    color: #000;
}
.box .steps ul li:first-child div {
    width: 175px;
    /* height: 120px; */
    padding-left: 15px;
}
.box .steps ul li div::before {
    content: ' ';
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #ddd;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 1;
    margin-left: 1px;
}
.box .steps ul li div::after {
    content: ' ';
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #ee1111;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
}
.box .steps ul li.done div {
    border-color: #ed0303 !important;
    color: rgb(231, 22, 22) !important;
    background: #03ed9f !important;
    /* height: 120px; */
}
.box .steps ul li.done div::after {
    border-left: 30px solid rgb(0, 128, 255);
}
.box .steps ul li.active div {
    border-color: #e60808 !important;
    color: #fff !important;
    background: #e60808 !important;
}
.box .steps ul li.active div::after {
    border-left: 30px solid rgb(249, 145, 145);
}

.box .step-component {
    padding: 20px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.box .btn-component {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.modal-header {
    color: #0043ff;
    font-weight: bold;
    font-size: large;
}
.sidebar[data-active-color='info'] .nav li.active > a,
.sidebar[data-active-color='info'] .nav li.active > a i {
    color: #0043ff;
}
a:hover {
    color: darkorange;
}
.prestation,
.quantity,
.prix {
    border: 1px solid #fff;
    color: #0043ff;
    font-weight: bold;
    border-radius: 5px;
}
.prestation:hover,
.quantity:hover,
.prix:hover {
    border: 1px solid rgb(243, 167, 4);
}
.prestation:focus,
.quantity:focus,
.prix:focus {
    border: 1px solid rgb(243, 167, 4);
    font-weight: bold;
}

.prestation {
    width: 100%;
    height: 30px;
}
.quantity {
    width: 50px;
    height: 30px;
    text-align: center;
}
.prix {
    width: 120px;
    height: 30px;
    text-align: center;
}

#ligh,
#succe {
    text-align: center;
    font-weight: bold;
}
#ligh {
    color: rgb(175, 0, 29);
}
#succe {
    color: darksalmon;
}

.input-group-text {
    background-color: transparent;
    border: 0;
}
.input-group .form-control {
    border: 1px solid #dddddd;
}
.input-group .form-control:first-child {
    border-right: 1px solid #dddddd;
}
.input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 4px;
}
.input-group .form-control:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
    border: 1px solid #25a45e;
}
.input-group .form-control:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
    border: 1px solid #25a45e;
}
.input-group .form-control:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
    border: 1px solid #25a45e;
}
.navbar.navbar-absolute {
    padding-top: 0px;
}

.mb-3 {
    margin-bottom: 0.2rem !important;
}

.row label {
    color: #1f1e1e;
    font-size: 1em;
    font-weight: bold;
}
.radio {
    padding: 5px;
}

.modal-footer {
    padding: 0;
}
.form-control {
    border: 1px solid #dee2e6;
    color: #0043ff;
    font-weight: bold;
}
#fixed-card {
    position: fixed;
}
@media screen and (max-width: 640px) {
    #fixed-card {
        position: relative;
    }
}

@media screen and (min-width: 992px) {
    .sidebar .sidebar-wrapper li.active > a:not([data-toggle='collapse']):after {
        border-right: 17px solid #0043ff;
    }
}

thead,
tfoot {
    background-color: #505151;
    color: #fff;
}

tbody {
    background-color: #e4f0f5;
}

caption {
    padding: 10px;
    caption-side: bottom;
}

.table {
    border-collapse: collapse;
    border: 2px solid rgb(200, 200, 200);
    letter-spacing: 1px;
    font-family: sans-serif;
    font-size: 0.8rem;
}

td,
th {
    border: 1px solid rgb(190, 190, 190);
    padding: 5px 10px;
    text-align: center;
}
th {
    font-size: 18px;
}
td {
    text-align: center;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
    padding: 5px 7px;
    vertical-align: middle;
}
