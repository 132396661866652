.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
  cursor: pointer;
}

#table1 .rgt-row-selected {
  background: #d0ffdb;
}
#table1 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* height: 650px;
    min-height: 388px; */
}
#table1 .rgt-row-hover {
  background: #d0ffdb;
}
#table1 .rgt-row-hover .menudrop {
  visibility: visible;
}
.menudrop {
  visibility: hidden;
  position: absolute;
  margin-top: -12px;
  background: rgb(255, 255, 255);
  padding: 2px;
  left: -30%;
  border-radius: 30px;
  height: 25px;
  z-index: 10;
}
.menudropp {
  visibility: hidden;
  position: absolute;
  margin-top: -20px;
  background: rgb(255, 255, 255);
  padding-top: 10px;
  left: 900%;
  top: 50%;
  height: 50px;
  z-index: 10;
}
.menudrops {
  visibility: visible;
  position: absolute;
  margin-top: -20px;
  padding-top: 4px;
  background: rgb(255, 255, 255);
  height: 40px;
  opacity: 0.15;
  left: 0;
}
.rgt-cell {
  color: slategre;
}

#tablecontent .InovuaReactDataGrid__row-hover-target:hover .menudrop {
  visibility: visible;
}
#tablecontent .InovuaReactDataGrid__row-hover-target:hover .menudrops {
  opacity: 1;
  visibility: visible;
}
#tablecontent .InovuaReactDataGrid__row-hover-target:hover .imgart {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  position: fixed;
  left: 0;
  top: -47px;
  z-index: 100;
  transition-duration: 0.6s;
}

.main-panel .contents {
  margin-top: 50px;
}
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 2px 7px;
}
