.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
    cursor: pointer;
  }
  
  .rgt-row-selected {
    background: #f3f6f9;
  }
  #table2,#table3{
    display: flex;
    flex-direction: column;
    /* position: relative; */
    width: 100%;
  }
  #table2{
    height: 650px;
    min-height: 388px;
  }
  #table3{
    height: auto;
  }
  #table2 .rgt-row-hover {
    background:rgb(255, 71, 71, 0.5);
  }
  .menudropg{
    background-color: #f3f6f9;
    height: 50px;
    visibility: hidden;
    padding-top: 10px;
    opacity: 0.3;
    z-index: 100;
  }
  #table2 .rgt-row-hover .menudropg{
    visibility: visible;
    transition-duration:  0.5s;
    opacity: 1;
  }
  
  .rgt-cell{
    color: slategre;
  }
  #table2 .rgt-search-input{
    border: 1px solid #d0d0d1;
  }
  